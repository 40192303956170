import React from 'react';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/slider-style.css';

const CustomSlider = () => {
  const navigate = useNavigate();

  const onLearnSongsTextClick = () => {
    navigate("/learn-song-list");
  };

  const onBattlesTextClick = useCallback(() => {
    navigate("/battles");
  }, [navigate]);

  const onChallengesTextClick = useCallback(() => {
    navigate("/challenges");
  }, [navigate]);

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onMakeMoneyTextClick = useCallback(() => {
    navigate("/make-money");
  }, [navigate]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <Slider {...settings}>
        <div className="p-2">
          <div className="d-flex ob-card ob-card-1 rounded-5">
            <div className="d-flex flex-column align-items-center gap-4 p-4 p-md-5 mx-auto">
              <div className="d-flex w-100">
                <img src="/images/onboarding/gbc-onboarding-logo.svg" alt="" className="img-fluid w-100" />
              </div>
              <div className="d-flex flex-column gap-4 justify-content-start justify-content-md-center h-100">
                <div className="d-flex gap-4 gap-md-4 align-items-center w-100">
                  <div className="rounded-4 overflow-hidden thumbnail-max flex-shrink-0">
                    <img src="/images/onboarding/gbc-onboarding-2.png" alt="" className="w-100 mw-100" />
                  </div>
                  <div className="d-flex flex-column">
                    <h4>Learn to play</h4>
                    <p className="mb-0">Choose a song, start learning and get feedback.</p>
                  </div>
                </div>
                <div className="d-flex gap-4 gap-md-4 align-items-center w-100">
                  <div className="rounded-4 overflow-hidden thumbnail-max flex-shrink-0">
                    <img src="/images/onboarding/gbc-onboarding-1.png" alt="" className="w-100 mw-100" />
                  </div>
                  <div className="d-flex flex-column">
                    <h4>Battle arena</h4>
                    <p className="mb-0">Challenge other guitar players, share and get voted to win the battle.</p>
                  </div>
                </div>
                <div className="d-flex gap-4 gap-md-4 align-items-center w-100">
                  <div className="rounded-4 overflow-hidden thumbnail-max flex-shrink-0">
                    <img src="/images/onboarding/gbc-onboarding-4.png" alt="" className="w-100 mw-100" />
                  </div>
                  <div className="d-flex flex-column">
                    <h4>Make money</h4>
                    <p className="mb-0">Upload your lessons and get rewards each time a player learns your songs.</p>
                  </div>
                </div>
                <div className="d-flex mx-auto">
                  <button onClick={onLogInTextClick} className="bttn bttn-yellow fw-bold fw-md-normal px-4">Log in</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2">
          <div className="d-flex ob-card ob-card-2 rounded-5">
            <div className="d-flex flex-column align-items-center gap-4 p-4 p-md-5 mx-auto">
              <div className="d-flex w-100">
                <img src="/images/onboarding/gbc-onboarding-logo.svg" alt="" className="img-fluid w-100" />
              </div>
              <div className="d-flex flex-column gap-4 justify-content-start justify-content-md-center h-100 text-center">
                <div className="d-flex flex-column gap-4 gap-md-4 align-items-center w-100">
                  <div className="rounded-4 overflow-hidden has-thumbnail">
                    <img src="/images/onboarding/gbc-onboarding-2.png" alt="" className="w-100 mw-100" />
                  </div>
                  <div className="d-flex flex-column">
                    <h3>Learn to play</h3>
                    <p className="mb-0">Dive into our vast song library, start practicing today, and get expert feedback as you progress through the levels.</p>
                  </div>
                </div>
                <div className="d-flex mx-auto">
                  <button onClick={onLearnSongsTextClick} className="bttn bttn-yellow fw-bold fw-md-normal px-4">Search songs</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2">
          <div className="d-flex ob-card ob-card-3 rounded-5">
            <div className="d-flex flex-column align-items-center gap-4 p-4 p-md-5 mx-auto">
              <div className="d-flex w-100">
                <img src="/images/onboarding/gbc-onboarding-logo.svg" alt="" className="img-fluid w-100" />
              </div>
              <div className="d-flex flex-column gap-4 justify-content-start justify-content-md-center h-100 text-center">
                <div className="d-flex flex-column gap-4 gap-md-4 align-items-center w-100">
                  <div className="rounded-4 overflow-hidden has-thumbnail">
                    <img src="/images/onboarding/gbc-onboarding-1.png" alt="" className="w-100 mw-100" />
                  </div>
                  <div className="d-flex flex-column">
                    <h3>Battle arena</h3>
                    <p className="mb-0">Step into the Battle arena, challenge other guitar players, and prove your skills. Share your battles, get votes, advance levels and win rewards.</p>
                  </div>
                </div>
                <div className="d-flex mx-auto">
                  <button onClick={onBattlesTextClick} className="bttn bttn-yellow fw-bold fw-md-normal px-4">See battles</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2">
          <div className="d-flex ob-card ob-card-4 rounded-5">
            <div className="d-flex flex-column align-items-center gap-4 p-4 p-md-5 mx-auto">
              <div className="d-flex w-100">
                <img src="/images/onboarding/gbc-onboarding-logo.svg" alt="" className="img-fluid w-100" />
              </div>
              <div className="d-flex flex-column gap-4 justify-content-start justify-content-md-center h-100 text-center">
                <div className="d-flex flex-column gap-4 gap-md-4 align-items-center w-100">
                  <div className="rounded-4 overflow-hidden has-thumbnail">
                    <img src="/images/onboarding/gbc-onboarding-4.png" alt="" className="w-100 mw-100" />
                  </div>
                  <div className="d-flex flex-column">
                    <h3>Make money</h3>
                    <p className="mb-0">After you win battles and become a guitar master, you will be able to upload your lessons and get paid each time a player learns your guitar lesson.</p>
                  </div>
                </div>
                <div className="d-flex mx-auto">
                  <button onClick={onMakeMoneyTextClick} className="bttn bttn-yellow fw-bold fw-md-normal px-4">Find out more</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
};

export default CustomSlider;

