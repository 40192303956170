import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getMyLessons } from "../../api/lessons";
import { useAuth } from "../../providers/AuthProvider";
import { Song } from "../../models/Song";
import SongCardsContainer, {
  SongCardsContainerProps,
} from "../songs/SongCardsContainer";
import { LearnSongsCardType } from "../songs/LearnSongsCard";

const MyLessons: FunctionComponent = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();
  const { tokens } = authState;
  const [songLessons, setSongLessons] = useState<Song[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchLessons = async () => {
      try {
        const accessToken = tokens?.access_token || "";
        const songLessons: Song[] = await getMyLessons(accessToken);
        setSongLessons(songLessons);
        setLoading(false);
      } catch (error) {
        console.error("Add lesson failed:", error);
        setLoading(false);
      }
    };

    fetchLessons();
  }, []);

  const onClickSong = useCallback(
    (song: LearnSongsCardType) => {
      navigate("/learn-song-play-lesson", { state: { song } });
    },
    [navigate]
  );

  const songsProps: SongCardsContainerProps = {
    songs: songLessons,
    childAction: onClickSong,
    pageCount: 0,
  };

  return (
    <>
      <div className="bg-yellow py-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  My lessons
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Here are the songs that you chose to practice and learn.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5">
          <SongCardsContainer
            childAction={songsProps.childAction}
            songs={songsProps.songs}
            showLevel={true}
            pageCount={0}
          />
        </div>
      </div>
    </>
  );
};

export default MyLessons;
