import React from "react";
import { UserVideo } from "../../models/UserVideo";
import { formatDate } from "../../utility/DateFormatter";
interface MyVideoCardProps {
  id: string;
  video: UserVideo;
  onTryAgainClick: (songId?: string) => void;
  onFindOpponentClick: (userVideo: UserVideo) => void;
  onDelete: (video: UserVideo) => void;
}

const MyVideoCard: React.FC<MyVideoCardProps> = ({
  id,
  video,
  onTryAgainClick,
  onFindOpponentClick,
  onDelete
}) => {
  const localCreatedDate = formatDate(new Date(video.createdAt));
  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="card-songs card-songs-listing d-flex flex-column h-100">
        <div className="song-image">
          <div className="p-3 p-md-4">
            <div className="fs-6 text-white"><i className="fa-solid fa-upload me-2"></i> {localCreatedDate.toLocaleString()}</div>
            <div className="fs-6 text-white"><i className="fa-solid fa-star me-2"></i>
              {video.reviewState === "pending" ? (
                <span>Waiting feedback</span>
              ) : (
                <span>{video.percentage}%</span>
              )}
            </div>
          </div>
          <img
            crossOrigin="anonymous"
            src={video?.thumbnailUrl}
          />
        </div>
        <div className="song-info px-3 px-md-4 py-3">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h3 className="fs-4 m-0 fw-bold">{video.song?.artistName || " "}</h3>
              <p className="mb-0 fs-6">{video.song?.songTitle || " "}</p>
            </div>
          </div>
        </div>
        <div className="p-3 p-md-4 d-flex">
          {video.percentage < 85 ? (
            <div className="d-flex justify-content-between w-100">
              <button
                onClick={() => onTryAgainClick(video.song?.id)}
                className="bttn bttn-stroke align-self-start"
              >
                Upload new
              </button>

              <button
                onClick={() => onDelete(video)}
                className="bttn bttn-stroke align-self-end"
              >
                Delete
              </button>
            </div>
          ) : (
            <button
              onClick={() => onFindOpponentClick(video)}
              className="bttn bttn-stroke align-self-start"
              disabled={video.percentage < 85}
            >
              Find opponent
            </button>
          )}
        </div>
      </div>
    </div >
  );
};

export default MyVideoCard;
