import React, { useState, useEffect } from "react";
import { Level } from "../../models/Level";
import { useAuth } from "../../providers/AuthProvider";
import { getLevels } from "../../api/levels";

interface LevelSelectorProps {
  onLevelClick: (level: Level) => void;
  onSubLevelClick: (subLevel: string) => void;
  onSubLevelCloseClick: () => void;
}

const LevelSelector: React.FC<LevelSelectorProps> = ({
  onLevelClick,
  onSubLevelClick,
  onSubLevelCloseClick,
}) => {
  const [levels, setLevels] = useState<Level[]>([]);
  const tokens = useAuth();

  useEffect(() => {
    getLevels(tokens.authState.tokens?.access_token ?? "").then((levels) => {
      setLevels(levels);
    });
  }, []);



  const [selectedLevel, setSelectedLevel] = useState<Level>((): Level => {
    const value = localStorage.getItem("selectedLevel");
    const level =
      levels.find((level) => level.id === value) ??
      new Level("beginner", "Beginner", ["all"]);
    return level;
  });
  const [selectedSubLevel, setSelectedSubLevel] = useState<string>("all");

  const handleLevelClick = (level: Level) => {
    setSelectedLevel(level);
    localStorage.setItem("selectedLevel", level.id);
    onLevelClick(level);

    setSelectedSubLevel("all");
  };

  const [scroll, setScroll] = useState(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
    onLevelClick(selectedLevel);
  }, []);

  const handleSubLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const subLevel = e.target.value;
    setSelectedSubLevel(subLevel);
    onSubLevelClick(subLevel);
  };

  const handleCloseClick = () => {
    setActiveButton(null);
    onSubLevelCloseClick();
  };

  return (
    <div
      className={
        scroll
          ? "scrolled bg-yellow border-bottom sticky-top-custom"
          : "not-scrolled bg-yellow border-bottom sticky-top-custom"
      }
    >
      <div className="d-flex flex-row justify-content-center align-items-center gap-2">
        <button
          className="rounded-pill close-category-levels px-0 bttn bttn-smaller"
          onClick={handleCloseClick}
          style={{ display: activeButton ? "block" : "none" }}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.34315 1.34313L12.6569 12.6568M1.34315 12.6568L12.6569 1.34313"
              stroke="white"
              strokeLinecap="round"
            ></path>
          </svg>
        </button>
        <div className="d-flex flex-row flex-md-row flex-wrap">
          {levels.map((level) => (
            <div className="d-flex" key={level.id}>
              <button
                className={
                  selectedLevel.id === level.id
                    ? "bttn bttn-smaller me-2"
                    : "bttn bttn-stroke bttn-smaller me-2"
                }
                onClick={() => {
                  handleLevelClick(level);
                  setActiveButton(level.id);
                }}
                style={{
                  display:
                    !activeButton || activeButton === level.id
                      ? "block"
                      : "none",
                }}
              >
                {level.name}
              </button>
              <select
                name=""
                id=""
                className="form-select category-levels rounded-pill px-4 pe-5 py-2 rounded-pill"
                style={{
                  display: activeButton === level.id ? "block" : "none",
                }}
                value={selectedSubLevel}
                onChange={handleSubLevelChange}
              >
                <option value="all">
                  All levels
                </option>
                {selectedLevel.subLevels.map((subLevel, index) => (
                  <option key={index} value={subLevel}>
                    {subLevel}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LevelSelector;
