import React, { useEffect, useState } from "react";

const GuitarTunerEmbed: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadScript = async () => {
      try {
        setLoading(true);
        const script = document.createElement("script");
        script.src = "https://embed.proguitar.com/web-guitar-tuner";
        script.type = "text/javascript";
        script.async = true;

        // Apply preconnect and preload attributes
        script.setAttribute("preconnect", "https://embed.proguitar.com");
        script.setAttribute("preload", "true");

        script.onload = () => {
          setLoading(false);
        };
        document.body.appendChild(script);
      } catch (error) {
        console.error("Error loading script:", error);
      }
    };

    loadScript();

    return () => {};
  }, []);

  return (
    <div>
      <div className="text-center mb-3">
        {loading && (
          <div className="position-absolute top-50 start-50 translate-middle">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div id="PGTContainer" style={{ width: "100%", height: "auto" }}>
        {/* Placeholder div for the guitar tuner */}
      </div>
    </div>
  );
};

export default GuitarTunerEmbed;
