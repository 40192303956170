import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { signUpWithEmail } from "../../api/signup";
import { loginGoogle } from "../../api/login";
import { useAuth } from "../../providers/AuthProvider";
import SignUpWithEmailForm from "./SignUpWithEmailForm";
import axios, { AxiosError } from "axios";

interface SignUpStep1Props {
  continue: () => void;
}

const SignUpStep1: FunctionComponent<SignUpStep1Props> = (props) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onClickSignUpFacebook = async () => { };

  const onClickSignUpGoogle = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      try {
        const { accessToken, refreshToken, user, lessons } = await loginGoogle(
          codeResponse.code
        );
        if (accessToken && user) {
          login(user, {
            access_token: accessToken,
            refresh_token: refreshToken,
          });
          if (lessons && lessons.length > 0) {
            navigate("/my-lessons");
          } else {
            navigate("/learn-song-list");
          }
        } else {
          console.error(
            "Google sign in failed: Access token or user not available"
          );
        }
      } catch (error) {
        console.error("Google sign up failed:", error);
      }
    },
    flow: "auth-code",
  });

  const onClickSignUpWithEmail = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    try {
      const { accessToken, user } = await signUpWithEmail(
        data.firstName,
        data.lastName,
        data.email,
        data.password
      );
      login(user, { access_token: accessToken, refresh_token: "" });
      navigate("/learn-song-list");
    } catch (error: any) {
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessage = (responseData as { message: string }).message;
        console.error("Request failed:", errorMessage);
        setError(errorMessage);
      } else {
        console.error("Sign up failed:", error);
      }
    }
  };

  return (
    <>
      <div className="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
        <div className="rounded-5 p-3 p-md-4 my-4 card-songs">
          {/* Warning */}
          {error && <div style={{ color: "red" }}>{error}</div>}
          <div className="sign-up">
            <h3 className="fw-bold fs-1 mb-0">Welcome!</h3>
            <p className="fs-gbc-2 fw-bold mb-0">Register with your e-mail.</p>

            <SignUpWithEmailForm continue={onClickSignUpWithEmail} />
            {/* <button className="bttn" onClick={onClickSignUpFacebook}>
          <i className="me-2 fa-brands fa-facebook-f"></i> Facebook
        </button> */}
            <button className="d-flex gap-2 align-items-center justify-content-center bttn bttn-stroke-yellow w-100 mt-3" onClick={onClickSignUpGoogle}>
              Or register with <div><i className="me-2 fa-brands fa-google"></i><span className="fw-bold">Google</span></div>
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
        <div className="rounded-5 p-4 pb-3 pb-md-4 bg-white border border-1 text-center">
          <p className="text-black fs-gbc-2 fw-bold">
            Already have an account?{" "}
          </p>
          <button
            className="bttn"
            onClick={onLogInTextClick}
          >
            Log in now
          </button>
        </div>
      </div>
    </>
  );
};

export default SignUpStep1;
