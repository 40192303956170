import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import api from "../../api/config/apiConfig";
import axios, { AxiosError } from "axios";
import { useAuth } from "../../providers/AuthProvider";
import {
  setVideoInDB,
  getVideoFromDB,
  clearOldVideos,
} from "../../services/IndexedDB";

type VideoPlayerProps = {
  videoUrl: string;
  thumbnailUrl?: string;
  isPrivate: boolean; // Indicates whether the video URL requires authorization
};

const MAX_VIDEO_SIZE_BYTES = 700 * 1024 * 1024; // 700 MB
const MAX_CACHE_ITEMS = 50;

const AWSVideoPlayerForm: FunctionComponent<VideoPlayerProps> = ({
  videoUrl,
  thumbnailUrl,
  isPrivate,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [videoNotFound, setVideoNotFound] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading
  const { tokens } = useAuth()?.authState || { tokens: undefined };

  useEffect(() => {
    const fetchVideoBlob = async () => {
      setLoading(true); // Set loading to true when starting to fetch the video

      // Try to get the video from IndexedDB first
      const cachedVideoBlob = await getVideoFromDB(videoUrl);
      if (cachedVideoBlob) {
        setVideoBlob(cachedVideoBlob);
        setLoading(false); // Video has been loaded
        return;
      }

      try {
        const headers: Record<string, string> = {
          Range: `bytes=0-${MAX_VIDEO_SIZE_BYTES - 1}`,
        };

        if (isPrivate) {
          headers.Authorization = `Bearer ${tokens?.access_token}`;
        }

        console.log("Load video url " + videoUrl + " is private " + isPrivate);
        console.log(headers);

        const response = await api.get(
          isPrivate ? `/videos/video-stream` : `/videos/video-stream-public`,
          {
            responseType: "blob",
            headers: headers,
            params: { filePath: `${videoUrl}` },
            timeout: 0, // Disable the timeout for this request
          }
        );

        setVideoBlob(response.data);
        setLoading(false); // Video has been loaded

        // Save the video to IndexedDB
        await setVideoInDB(videoUrl, response.data);

        // Clear old videos if necessary
        await clearOldVideos(MAX_CACHE_ITEMS);
      } catch (error: any) {
        console.error("Error fetching video URL:", error);
        setLoading(false); // Loading failed
        if ((error as AxiosError).response?.status === 404) {
          setVideoNotFound(true);
        }
      }
    };

    if (videoUrl) {
      console.log("Fetch video");
      fetchVideoBlob();
    }
  }, [videoUrl, tokens?.access_token]);

  useEffect(() => {
    if (videoBlob && videoRef.current) {
      const blobUrl = URL.createObjectURL(videoBlob);
      videoRef.current.src = blobUrl;

      // Clean up the blob URL when the component unmounts or when the blob changes
      return () => URL.revokeObjectURL(blobUrl);
    }
  }, [videoBlob]);

  const handleVideoLoaded = () => {
    setLoading(false); // Video has been loaded
  };

  return (
    <div
      className="video-container"
      style={{ position: "relative", width: "100%", height: "auto" }}
    >
      {loading && (
        <div
          className="loading-spinner"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
            borderRadius: "50%",
            padding: "1rem",
            color: "white",
          }}
        >
          Loading video...
        </div>
      )}
      {!videoNotFound ? (
        <video
          ref={videoRef}
          controls
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "1rem",
          }}
          controlsList="nodownload"
          poster={thumbnailUrl}
          onLoadedData={handleVideoLoaded} // Hide the spinner when the video is ready to play
          onPlay={() => setLoading(false)} // Ensure the spinner is hidden when the video starts playing
        />
      ) : (
        <div style={{ padding: "1rem", textAlign: "center", color: "red" }}>
          Video not found
        </div>
      )}
    </div>
  );
};

export default React.memo(AWSVideoPlayerForm);
