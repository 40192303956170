import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

interface ModalProps {
  onClose: () => void;
  show: boolean;
  title: string;
  children: React.ReactNode;
}

const ModalComponent: React.FC<ModalProps> = ({
  onClose,
  show,
  title,
  children
}) => {
  
  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: "100%", minHeight: "560px" }}>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <button className="bttn" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
