import React, { useState, ChangeEvent, FormEvent } from "react";
import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";

interface SignInWithEmailProps {
  continue: (data: { email: string; password: string }) => void;
  onForgotPasswordClick: () => void;
  clearErrorMessage: () => void;
}

const SignInWithEmailForm: React.FC<SignInWithEmailProps> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.continue({ email, password });
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    props.clearErrorMessage();
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    props.clearErrorMessage();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row mt-3 mt-md-5">
          {/* Email input */}
          <div className="col-12 col-md-12">
            <EmailInput value={email} onChange={handleEmailChange} />
          </div>
          {/* Password input */}
          <div className="col-12 col-md-12">
            <PasswordInput
              name="Password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-12">
            <button className="text-black text-decoration-none fw-bold bg-transparent border-0 mx-0 px-0" onClick={props.onForgotPasswordClick}>Forgot your password?</button>
          </div>
          <div className="col-12 col-md-12 d-flex">
            <button type="submit" className="bttn w-100 mt-3 mt-md-4">
              Log in now
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignInWithEmailForm;
