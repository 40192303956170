import { FunctionComponent, useCallback } from "react";

const TermsAndConditons: FunctionComponent = () => {

  return (
    <>
      <div className="bg-yellow py-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  Terms and Conditions
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Last update: 12.05.2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="card-songs p-3 p-md-5 bg-white">
                <p>This document (together with all the documents mentioned in it) sets out the terms and conditions governing the use of this website - www.guitarbattleclub.com (hereinafter the "Website"). </p>
                <p>The purpose of the Website is to provide users with guitar lessons through the mediation of access to the services of a music teacher as well as to provide the opportunity for users to register in competitions (battles) with other users to promote and improve their musical skills . </p>
                <p>Please read these Terms and Conditions, Cookie Policy and Privacy Policy before using the Website. By accessing and using the Website, you expressly acknowledge the binding nature of these documents and undertake to comply with them. </p>
                <p>By accessing the Site and creating a user account, you the user certify and warrant to us that you are at least 16 years of age. At this time, the use of the site by minors who have not reached the age of 16 is prohibited. </p>
                <h3 className="fw-bold mt-4">1. Who we are?</h3>
                <p>Guitar Battle Club (hereinafter may be referred as ``the Company, the Controller or ``We'').</p>
                <p><strong>Our contact details are:</strong><br />Headquarters address: 22 Plaiului Street, Nazna, Mures County, Romania<br />Email address: info(at)guitarbattleclub.com</p>
                <h3 className="fw-bold mt-4">2. Intelectual property</h3>
                <p>The company is the exclusive or non-exclusive owner of all intellectual property rights related to the content of the Website, including, but not limited to: articles, text, photos, illustrations, music, audio and video clips, trademarks, emblems and designs, accordingly.</p>
                <p>BY USING THIS SITE AND THE FACILITIES MADE AVAILABLE TO USERS, IN PARTICULAR IF YOU CHOOSE TO UPLOAD VIDEO CLIPS OF YOUR MUSICAL PERFORMANCES, YOU AGREE TO THE A FREE, NON-EXCLUSIVE ASSIGNMENT TO US, THE COMPANY, FOR AN UNLIMITED PERIOD OF TIME, FOR USE WORLDWIDE AND IN ANY MEDIA, OF THE RIGHTS TO REPRODUCE, DISTRIBUTE, RENT, LOAN, RE-TRANSMIT AND COMMUNICATE TO THE PUBLIC THROUGH ANY TECHNICAL MEANS AS WELL AS THE RIGHT TO MAKE DERIVATIVE WORKS OF THE VIDEOS UPLOADED ON THE PLATFORM. IN ALL CASES THE COPYRIGHT OF THE ARTISTIC CREATION - THE RIGHT TO BE IDENTIFIED AS THE PERFORMER OF THE MELODY AND THE SUBJECT OF THE VIDEO - WILL BELONG EXCLUSIVELY TO YOU, THE ARTIST. BY ACCEPTING THESE TERMS AND CONDITIONS YOU ASSURE US, THE COMPANY, THAT YOU ARE THE CREATOR OF THE VIDEO-AUDIO PERFORMANCE AS WELL AS THE CREATOR OF THE VIDEO AND THAT YOU OWN THE EXCLUSIVE INTELLECTUAL RIGHTS TO THE UPLOADED MATERIAL AND THAT YOU HAVE THE LEGAL CAPACITY TO ASSIGN RIGHTS TO THE UPLOADED VIDEO AND AUDIO MATERIAL.</p>
                <p>Website visitors can access, download and print the materials published on the Website, free of charge, exclusively for personal use and only for non-commercial purposes. As a result of performing any of these actions, no right, title or legitimate interest in the materials published on the Website is conveyed or recognized to the Website visitors.</p>
                <p>No section of the Website content over which the Company has an exclusive intellectual property right may be reproduced, distributed or published in any form, for any purpose or by any means, without the prior written consent of the Company. Therefore, Website visitors declare that, by simply using the Website, they understand and agree not to copy, modify, sell, distribute, transmit, display, reproduce, publish or create works/works/derivatives based on the published information on the Website.</p>
                <h3 className="fw-bold mt-4">3. Use of the website</h3>
                <p>By using the Website you obligated to:</p>
                <ul>
                  <li>a) Provide us with your first and last name, an email address and/or other correct and accurate contact details. If you do not provide us with all the information we need, we may not be able to respond to your request or fulfill your requests.</li>
                  <li>b) To use the website according to its intended use, which results from the declared purpose and the descriptions provided by the Company</li>
                  <li>c) Comply with the Terms and Conditions of Use</li>
                </ul>
                <p>By using the Website in any way and for any purpose, you declare that you meet the conditions provided for by law, respectively that you have the legal capacity to conclude binding legal documents that give rise to the obligations provided for in the Terms and Conditions, the Privacy Policy and the Cookies Policy -hate.</p>
                <p>The simple use of the Website and/or the transmission of one or more requests does not obligate the Company in any way towards you, respectively it does not represent the conclusion of a contract between the Company and you. Likewise, the creation of a user account and the eventual upload of some video clips cannot create an obligation on the part of the company to use these clips in any way.</p>
                <p>Any services that may be offered to users are provided as they exist at the time they are accessed (as-is), with you as the user being the only one in a position to judge whether they meet your needs.</p>
                <p>The Company makes no guarantees or promises as to the development in any way of your ability to play and use the guitar or other musical instruments. The application of any advice or guidance received falls entirely on you. The Company assumes no liability or guarantees regarding their effectiveness or safety.</p>
                <h3 className="fw-bold mt-4">4. Limitation of liability</h3>
                <p>The Company does not assume responsibility for any kind of damage that the Website visitor or any other third party may suffer as a result of using the Website in any way or purpose or as a result of the Company's compliance with any of its obligations legal and/or contractual obligations.</p>
                <p>The visitor will use the services offered through the Website entirely at his own risk. He is responsible for maintaining the confidentiality of the information transferred/received through the Website and for their processing in accordance with the legal provisions on the protection of personal data.</p>
                <p>The Company does not warrant or assume responsibility for the correctness, accuracy or timeliness of any such information.</p>
                <h3 className="fw-bold mt-4">5. Our right to modify the terms and conditions</h3>
                <p>We have the right to revise and modify the Terms and Conditions from time to time and at our discretion.</p>
                <p>You are responsible for reading the Terms and Conditions, the Cookies Policy and the Privacy Policy in full, as the updated version of them, in force at the time of using the Website, will be applicable.</p>
                <h3 className="fw-bold mt-4">6. Applicable law and jurisdiction</h3>
                <p>The use of the Website and the conclusion, execution and termination of contracts concluded with the Company through the Website will be governed by Romanian legislation.</p>
                <p>Any disputes arising from or in connection with the use of the Website or the conclusion, execution and termination of these contracts will be settled amicably or, if this is not possible, the disputes will be settled by the competent Romanian courts.</p>
                <h3 className="fw-bold mt-4">7. Personal data processing</h3>
                <p>The processing of personal data by the Company takes place in compliance with Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive no. 95/46/EC ("General Data Protection Regulation") and the relevant national legislation on the protection of personal data.</p>
                <p>To know more details related to the processing of personal data, please consult the Privacy Policy https://guitarbattleclub.com/privacy-policy</p>
                <p>Also, for any questions or requests regarding the protection of personal data, please contact us at the email address: info(at)guitarbattleclub.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditons;
