import React, { useState } from "react";
import ForgotPasswordRequestForm from "./ForgotPasswordRequestForm";
import { forgotPassword } from "../../api/forgotPassword";

interface ForgotPasswordContainerProps {
  onClose: () => void;
}

const ForgotPasswordContainer: React.FC<ForgotPasswordContainerProps> = (
  props
) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onForgotPassword = async (email: string) => {
    try {
      await forgotPassword(email);
      setShowSuccessMessage(true);
    } catch (error) {
      console.error("Forgot password failed:", error);
    }
  };

  const handleForgotPasswordClose = () => {
    props.onClose(); // Call the onClose function passed from props
  };

  return (
    <div>
      {showSuccessMessage ? (
        <div>
          <h3 className="fw-bold fs-1">Password reset sent</h3>
          <p>
            If you are registered with the provided email address, we have sent
            you an email to reset your password.
          </p>
          {/* Optionally, you can add a button to close */}
          <button className="bttn" onClick={handleForgotPasswordClose}>
            Close
          </button>
        </div>
      ) : (
        <ForgotPasswordRequestForm
          onForgotPassword={onForgotPassword}
          onClose={handleForgotPasswordClose}
        />
      )}
    </div>
  );
};

export default ForgotPasswordContainer;
