import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import Avatar from 'react-avatar-edit'
import { User, updateUser } from "../../api/users";

type AvatarType = {
    preview: string | undefined,
    src: string
}

const Profile: FunctionComponent = () => {
    const authState = useAuth().authState.user;
    const { updateUserInfo } = useAuth();
    const initialUser: User = {
        id: authState?.id || '0',
        firstName: authState?.firstName || '',
        lastName: authState?.lastName || '',
        email: authState?.email || '',
        phone: authState?.phone || '',
        picture: authState?.picture || '',
        nickname: authState?.nickname || ''
    }
    const [user, setUser] = useState<User>(initialUser);

    useEffect(() => {
        setUser({
            id: authState?.id || '0',
            firstName: authState?.firstName || '',
            lastName: authState?.lastName || '',
            email: authState?.email || '',
            phone: authState?.phone || '',
            picture: authState?.picture || '',
            nickname: authState?.nickname || ''
        })
        setState({ preview: authState?.picture, src: "" });
    }, [authState]);

    const [state, setState] = useState<AvatarType>({
        preview: user?.picture,
        src: ""
    });

    const onClose = () => {
        //setState({ ...state, preview: undefined })
    }

    const onCrop = (preview: string) => {
        setState({ ...state, preview })
        setUser({ ...user, picture: preview });
    }

    const onBeforeFileLoad = (elem: any) => {

    }

    const saveUser = async () => {
        // Implement updateUser function
        const response = await updateUser(user);
        updateUserInfo(response.firstName, response.lastName, response.picture, response.phone, response.nickname);
    }

    function phoneHasChanged(event: ChangeEvent<HTMLInputElement>): void {
        setUser({ ...user, phone: event.target.value });
    }

    function firstNameHasChanged(event: ChangeEvent<HTMLInputElement>): void {
        setUser({ ...user, firstName: event.target.value });
    }

    function lastNameHasChanged(event: ChangeEvent<HTMLInputElement>): void {
        setUser({ ...user, lastName: event.target.value });
    }

    function nicknameHasChanged(event: ChangeEvent<HTMLInputElement>): void {
        setUser({ ...user, nickname: event.target.value });
    }


    return (
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
          <div className="card-songs rounded-5 p-4 p-md-5 h-100 d-flex flex-column justify-content-between">
            <div className="d-flex gap-4 mb-3">
              <img
                crossOrigin="anonymous"
                src={state.preview}
                alt="Preview"
                width="58"
                height="58"
                className="border border-white rounded-circle bg-white"
              />
              <Avatar
                width={160}
                height={58}
                onCrop={onCrop}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                src={state.src}
              />
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="mail"
                    className="form-control"
                    id="user-firstname"
                    value={user?.firstName}
                    onChange={firstNameHasChanged}
                  />
                  <label htmlFor="user-email">Firstname</label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="mail"
                    className="form-control"
                    id="user-lastname"
                    value={user?.lastName}
                    onChange={lastNameHasChanged}
                  />
                  <label htmlFor="user-email">Lastname</label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="mail"
                    className="form-control"
                    id="user-email"
                    value={user?.email}
                  />
                  <label htmlFor="user-email">Email address</label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    id="user-phone"
                    value={user?.phone}
                    onChange={phoneHasChanged}
                  />
                  <label htmlFor="user-phone">Phone number</label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    id="user-battle-name"
                    value={user?.nickname}
                    onChange={nicknameHasChanged}
                  />
                  <label htmlFor="user-battle-name">Battle name</label>
                </div>
              </div>
              <div className="col-12 col-md-6 text-end">
                <button type="button" className="bttn" onClick={saveUser}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Profile;