import React from 'react';
import Profile from './Profile';

const MyProfilePage: React.FC = () => {
    return (
        <>
            <div className="bg-yellow py-5 border-bottom">
                <div className="container-gbc pt-5 text-black">
                <div className="row text-center pt-5">
                    <div className="col-12">
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                        <h2 className="fw-normal fs-gbc-1 mb-2">
                            My profile
                        </h2>
                        <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                            Manage your informations.
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="bg-green">
                <div className="container-gbc py-4 py-md-5">
                    <Profile />
                </div>
            </div>
        </>
    );
};

export default MyProfilePage;