import { AxiosRequestConfig } from "axios";
import api, { apiBaseUrl } from "./config/apiConfig";
import { UserVideo } from "../models/UserVideo";
import { logger as Logger, LogLevel } from "../services/Logger";

export const uploadVideo = async (
  accessToken: string,
  videoBlob: Blob,
  extraParams: Record<string, any>
) => {
  try {
    const formData = new FormData();
    formData.append("video", videoBlob);

    // Append extra parameters to the FormData
    Object.entries(extraParams).forEach(([key, value]) => {
      formData.append(key, value);
    });

    await api.post(`${apiBaseUrl}/video`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    Logger.error("Error uploading video:", error);
    throw error;
  }
};

export const uploadVideoFormData = async (
  formData: FormData,
  config: AxiosRequestConfig
) => {
  try {
    Logger.log(LogLevel.DEBUG, "Axios Request Config:", config);
    await api.post(`${apiBaseUrl}/video`, formData, config);
  } catch (error) {
    Logger.error("Error uploading video:", error);
    throw error;
  }
};

export const getUserVideos = async (
  accessToken: string
): Promise<UserVideo[]> => {
  try {
    const response = await api.get(`${apiBaseUrl}/videos`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get user videos failed");
    }
  } catch (error) {
    Logger.error("Get user videos failed:", error);
    throw error;
  }
};

export const deleteUserVideo = async (
  accessToken: string,
  videoId: string,
): Promise<void> => {
  try {
    await api.post(
      `${apiBaseUrl}/video/delete-video`,
      { videoId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      } as AxiosRequestConfig
    );
  } catch (error) {
    Logger.error("Error deleting video:", error);
    throw error;
  }
}
