import React, { useState, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import PasswordInput from "../sign_in/PasswordInput";
import { resetPassword } from "../../api/users";

interface ResetPasswordrops {
  onSuccess: () => void;
  onClose: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordrops> = (props) => {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const token = window.location.pathname.split("/").pop();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!token) {
      setError("Token not found. Please try again.");
      return;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    } else if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await resetPassword(token, password); // Call resetPassword API function
      // Password reset successful, clear form fields and errors
      setPassword("");
      setConfirmPassword("");
      setError("");
      props.onSuccess();
    } catch (error) {
      console.error("Reset password failed:", error);
      setError("Failed to reset password. Please try again.");
    }
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value); // Update password state directly
    setError(""); // Clear error message
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value); // Update confirmPassword state directly
    setError(""); // Clear error message
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <div className="rounded-5 p-5 p-md-5 my-5 card-songs">
      <h2>Reset Password</h2>
      {/* Warning */}
      {error && <div style={{ color: "red" }}>{error}</div>}
      <div className="sign-up">
        <form onSubmit={handleSubmit}>
          <div className="row mt-5">
            {/* Password input */}
            <div className="col-12 col-md-6">
              <PasswordInput
                name="Password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            {/* Confirm password input */}
            <div className="col-12 col-md-6">
              <PasswordInput
                name="Confirm password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </div>
          </div>
          <button className="bttn" type="submit">
            Reset Password
          </button>
          <button
            type="button"
            className="fw-semibold text-black text-decoration-underline"
            onClick={handleClose}
          >
            Back
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
