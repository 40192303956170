import React, { useState, useCallback } from "react";
import Metronome from "../codepen/Metronome";
import GuitarTunerModal from "../codepen/GuitarTunerModal";
import { getSongPdf } from "../../api/songs";
import { useAuth } from "../../providers/AuthProvider";
import { LearnSongsCardType } from "./LearnSongsCard";
import ModalComponent from "../components/ModalComponent";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";



interface SongToolsCardProps {
  song: LearnSongsCardType;
}

const SongToolsCard: React.FC<SongToolsCardProps> = ({ song }) => {
  const [showModalTuner, setShowModalTuner] = useState(false);
  const [showModalTips, setShowModalTips] = useState(false);
  const [showModalBackingTrack, setShowModalBackingTrack] = useState(false);
  const { authState } = useAuth();
  const { tokens } = authState;

  const handleOpenModal = (modal: string) => {
    handleCloseModal();
    switch (modal) {
      case "tuner":
        setShowModalTuner(true);
        break;
      case "tips":
        setShowModalTips(true);
        break;
      case "backingTrack":
        setShowModalBackingTrack(true);
        break;
      default:
        break;
    }
  }
  const handleCloseModal = () => {
    setShowModalTuner(false);
    setShowModalTips(false);
    setShowModalBackingTrack(false);
  }

  const onDownloadTabsClick = useCallback(
    async (pdfUrl?: string) => {
      if (!pdfUrl) {
        console.error("No PDF URL provided");
        return;
      }

      try {
        const accessToken = tokens?.access_token || "";
        const signedUrl = await getSongPdf(accessToken, pdfUrl);
        window.open(signedUrl, "_blank");
      } catch (error) {
        return;
      }
    },
    [tokens]
  );

  return (
    <div className="d-flex flex-column gap-3 gap-md-4 justify-content-center mb-4">
      <div className="order-2">
        <Metronome />
      </div>
      <div className="d-flex gap-1 flex-wrap justify-content-center order-1 order-md-1">
        <button
          onClick={() => handleOpenModal("tips")}
          className="bttn bttn-stroke bttn-smaller"
          disabled={!song.tips}
        >
          Tips
        </button>
        <ModalComponent
          onClose={handleCloseModal}
          show={showModalTips}
          title={"Tips"}
        >
          <AWSVideoPlayerForm
            videoUrl={song.tips ? song.tips : ""}
            isPrivate={true}
          />
        </ModalComponent>
        <button
          onClick={() => handleOpenModal("tuner")}
          className="bttn bttn-stroke bttn-smaller"
        >
          Tuner
        </button>
        <GuitarTunerModal onClose={handleCloseModal} show={showModalTuner} />
        <button
          onClick={() => onDownloadTabsClick(song.pdfUrl)}
          className="bttn bttn-stroke bttn-smaller"
          role="button"
          disabled={!song.pdfUrl}
        >
          Tabs
        </button>
        <button
          onClick={() => handleOpenModal("backingTrack")}
          className="bttn bttn-stroke bttn-smaller"
          disabled={!song.backingTrack}
        >
          Backing track
        </button>
        <ModalComponent
          onClose={handleCloseModal}
          show={showModalBackingTrack}
          title={"Backing track"}
        >
          <AWSVideoPlayerForm
            videoUrl={song.backingTrack ? song.backingTrack : ""}
            isPrivate={true}
          />
        </ModalComponent>
      </div>
    </div>
  );
};

export default SongToolsCard;
