import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import GuitarTunerEmbed from "./GuitarTunerEmbed";

interface GuitarTunerModalProps {
  onClose: () => void;
  show: boolean;
}

const GuitarTunerModal: React.FC<GuitarTunerModalProps> = ({
  onClose,
  show,
}) => {

  // const iFrameSrc =
  //   '<div><script width="100%" src="https://embed.proguitar.com/web-guitar-tuner"></script></div>';
  
  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Guitar Tuner</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: "100%", minHeight: "560px" }}>

        <GuitarTunerEmbed />

        {/* <iframe
          srcDoc={iFrameSrc}
          style={{ borderWidth: 0, width: "100%", minHeight: "560px" }}
          onLoad={() => setLoading(false)}
        ></iframe> */}
      </Modal.Body>
      <Modal.Footer>
        <button className="bttn" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default GuitarTunerModal;
