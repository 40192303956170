import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';

interface SocialShareProps {
  url: string;
  title: string;
}

const SocialShare: React.FC<SocialShareProps> = ({ url, title }) => {
  return (
    <div className="social-share d-flex gap-2">
      <FacebookShareButton url={url} hashtag="#GuitarBattleClub">
        <FacebookIcon size={44} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={44} round />
      </TwitterShareButton>
      <LinkedinShareButton url={url} title={title} summary={title}>
        <LinkedinIcon size={44} round />
      </LinkedinShareButton>
      <EmailShareButton url={url} subject={title}>
        <EmailIcon size={44} round />
      </EmailShareButton>
    </div>
  );
};

export default SocialShare;
