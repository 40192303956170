import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { getUserVideos } from "../../api/videos";
import MyVideoCard from "./MyVideoCard";
import { UserVideo } from "../../models/UserVideo";
import { Song } from "../../models/Song";
import { getSongById } from "../../api/songs";
import DeleteVideoConfirmationPopup from "../record_video/DeleteVideoConfirmationPopup";
import { Video } from "../../models/Video";

const MyVideos: FunctionComponent = () => {
  const [videos, setVideos] = useState<UserVideo[]>([]);
  const navigate = useNavigate();
  const { authState } = useAuth();
  const { tokens } = authState;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

  const fetchUserVideos = async () => {
    try {
      const accessToken = tokens?.access_token || "";
      const userVideos: UserVideo[] = await getUserVideos(accessToken);
      setVideos(userVideos);
    } catch (error) {
      console.error("Failed to fetch user videos:", error);
    }
  };

  useEffect(() => {
    fetchUserVideos();
  }, []);

  const onTryAgainClick = async (songId?: string) => {
    if (songId) {
      try {
        const accessToken = tokens?.access_token || "";
        const song: Song = await getSongById(accessToken, songId);
        song.hasUserVideo = true;
        navigate("/upload-video", { state: { song } });
      } catch (error) {
        console.error("Failed to fetch user videos:", error);
      }
    }
  };

  const onFindOpponentClick = async (userVideo: UserVideo) => {
    if (userVideo) {
      navigate("/find-opponent", { state: { userVideo } });
    }
  };

  return (
    <div>
      {showConfirmationPopup && selectedVideo && (
        <DeleteVideoConfirmationPopup onHide={() => setShowConfirmationPopup(false)}
          video={selectedVideo} token={tokens?.access_token || ""}
          onSucess={fetchUserVideos} />
      )}
      <div className="bg-yellow py-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  My videos
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Here you will find the videos that you recorded.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5">
          <div className="row">
            {videos.map((video) => (
              <MyVideoCard
                key={video.id}
                id={video.id}
                video={video}
                onTryAgainClick={onTryAgainClick}
                onFindOpponentClick={onFindOpponentClick}
                onDelete={(video) => {
                  setSelectedVideo({
                    id: Number.parseInt(video.id),
                    thumbnailUrl: `${video.thumbnailUrl}`,
                    videoUrl: `${video.videoUrl}`,
                    userId: 0,
                    songId: `0`,
                    reviewState: ``,
                    percentage: 0,
                    createdAt: new Date(video.createdAt)
                  });
                  console.log("selectedVideo", selectedVideo);
                  setShowConfirmationPopup(true);
                }
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyVideos;
