import { FC, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import DashboardElement from './components/DashboardElement';

const MyDashboard: React.FC = () => {
    const navigate = useNavigate();

  const onClickMyVideos = useCallback(() => {
    navigate("/my-videos");
  }, [navigate]);

    const onClickMyLessons = useCallback(() => {
    navigate("/my-lessons");
  }, [navigate]);
  
    const onClickMyBattles = useCallback(() => {
    navigate("/my-battles");
    }, [navigate]);
  
  return (
    <>
      <div className="bg-yellow py-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  My dashboard
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Manage your videos, lessons and battles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5">
          <div className="row">
            {/* <div className="col-12 col-md-6 col-lg-3 mb-4">
              <Profile />
            </div> */}
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <DashboardElement
                title={"My videos"}
                description={
                  "All your videos, with feedback and ready to battle."
                }
                buttontext={"My videos"}
                onClickButton={onClickMyVideos}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <DashboardElement
                title={"My lessons"}
                description={
                  "All your in progress lessons and feedbacks in one place."
                }
                buttontext={"My lessons"}
                onClickButton={onClickMyLessons}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <DashboardElement
                title={"My battles"}
                description={
                  "See who challenged you to a battle and who you got challaged."
                }
                buttontext={"My battles"}
                onClickButton={onClickMyBattles}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyDashboard;
