import {
  FunctionComponent,
  useEffect,
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SongCardsContainer from "./SongCardsContainer";
import LevelSelector from "./LevelSelector";
import { LearnSongsCardType } from "./LearnSongsCard";
import { fetchLearnSongsFromAPI } from "../../data/videosSlice";
import { useAuth } from "../../providers/AuthProvider";
import { Level } from "../../models/Level";
import { addLesson } from "../../api/lessons";
import { PayloadAction } from "@reduxjs/toolkit";

const LearnSongsListing: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { authState } = useAuth();
  const { tokens } = authState;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!tokens?.access_token);
  const [loading, setLoading] = useState(true);
  const [cachedSongs, setCachedSongs] = useState<{
    [levelId: string]: LearnSongsCardType[];
  }>({});
  const [filteredSongs, setFilteredSongs] = useState<LearnSongsCardType[]>([]);
  const [totalSongs, setTotalSongs] = useState<number>(0);

  // Initialize the current level ID
  var currentLevelId = localStorage.getItem("selectedLevel") || "beginner";
  var currentSubLevel = "all";
  var currentPage = 0;

  const SONGS_PER_PAGE = 9;

  useEffect(() => {
    setIsLoggedIn(!!tokens?.access_token);
  }, [tokens]);

  useEffect(() => {
    getSongs(currentLevelId, currentSubLevel, 0, SONGS_PER_PAGE);
  }, [navigate]);

  const onClickSong = useCallback(
    (song: LearnSongsCardType) => {
      if (song.hasUserBattle) {
        navigate("/my-battles");
      } else if (song.isUserLesson) {
        navigate("/learn-song-play-lesson", { state: { song } });
      } else {
        onAddToLesson(song);
      }
    },
    [navigate]
  );

  const onAddToLesson = async (song: LearnSongsCardType | undefined) => {
    if (song) {
      const accessToken = tokens?.access_token || "";
      try {
        await addLesson(accessToken, song.id);
        navigate("/learn-song-play-lesson", { state: { song } });
      } catch (error) {
        console.error("Error adding lesson:", error);
      }
    }
  };

  const getSongs = (level: string, subLevel: string, offset: number, limit: number) => {
    const accessToken = tokens?.access_token || "";
    // console.log("Fetching songs for level:", level);

    dispatch(fetchLearnSongsFromAPI({ accessToken, level: level, subLevel, offset, limit }))
      .then((action: PayloadAction<{
        songs: LearnSongsCardType[] | undefined,
        total: number
      }>) => {

        const fetchedSongs = action.payload.songs || [];
        setCachedSongs((prevCachedSongs) => ({
          ...prevCachedSongs,
          [level]: fetchedSongs,
        }));
        setFilteredSongs(fetchedSongs); // Update filteredSongs immediately after fetching
        setTotalSongs(action.payload.total);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLevelClick = (level: Level) => {
    currentLevelId = level.id;
    currentSubLevel = "all";
    setLoading(true);
    getSongs(level.id, "all", 0, 9);
  };

  const handleSubLevelClick = (subLevel: string) => {
    currentSubLevel = subLevel;
    if (subLevel === "all") {
      setFilteredSongs(cachedSongs[currentLevelId]);
    } else {
      filterSongs(subLevel);
    }
  };

  const handleSubLevelCloseClick = () => {
    console.log("Close");
    currentSubLevel = "all";
    setFilteredSongs(cachedSongs[currentLevelId]);
  };

  const filterSongs = (subLevel: string) => {
    getSongs(currentLevelId, subLevel, currentPage, SONGS_PER_PAGE);
  };

  return (
    <>
      <div className="bg-yellow pt-5">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  Pick your difficulty level
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  You can improve your skills in the most enjoyable way
                  possible, whether you're a beginner or an intermediate guitar
                  player.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <LevelSelector
          onLevelClick={handleLevelClick}
          onSubLevelClick={handleSubLevelClick}
          onSubLevelCloseClick={handleSubLevelCloseClick}
        />
        <div className="container-gbc py-4 py-md-5">
          {loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <SongCardsContainer
            childAction={onClickSong}
            songs={filteredSongs}
            pageCount={Math.ceil(totalSongs / SONGS_PER_PAGE)}
            showLevel={false}
            onPageChange={(data) => {
              currentPage = data.selected;
              getSongs(currentLevelId, currentSubLevel, currentPage, SONGS_PER_PAGE);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default LearnSongsListing;
