import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface SuccessVideoUploadPopupProps {
  onClose: () => void;
}

const SuccessVideoUploadPopup: React.FC<SuccessVideoUploadPopupProps> = ({
  onClose,
}) => {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Success!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your video has been successfully uploaded! It will be available on
          your videos page in a few minutes.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessVideoUploadPopup;
