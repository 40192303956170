import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const HowToPlay: FunctionComponent = () => {
  const navigate = useNavigate();

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  return (
    <>
      <div className="bg-yellow py-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  Make Money
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  You will be able to trade your points for cash with Guitar Battle Club.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card-songs d-flex flex-column p-3 p-md-4 h-100 gap-4 bg-white">
                <i className="fa-solid fa-trophy fs-3 mb-2"></i>
                <h3 className="fs-4 m-0 fw-bold">Win battles</h3>
                <p className="mb-0">After you win battles and become a guitar master, you will be able to upload your lessons and get paid each time a player learns from your guitar lesson.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card-songs d-flex flex-column p-3 p-md-4 h-100 gap-4 bg-white">
                <i className="fa-solid fa-spinner fs-3 mb-2"></i>
                <h3 className="fs-4 m-0 fw-bold">Accumulate points</h3>
                <p className="mb-0">Guitarists all over the world will be able to learn from you—a song, a part of a song, and when another player is learning your lessons, you will accumulate points.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card-songs d-flex flex-column p-3 p-md-4 h-100 gap-4 bg-white">
                <i className="fa-solid fa-graduation-cap fs-3 mb-2"></i>
                <h3 className="fs-4 m-0 fw-bold">Creative lessons</h3>
                <p className="mb-0">If you have original and creative lessons, you have the opportunity to do what you love, make nice bucks and become a famous guitarist.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card-songs d-flex flex-column p-3 p-md-4 h-100 text-center gap-2">
                <h3 className="fs-4 m-0 fw-bold">Become a teacher</h3>
                <p>Just click the button if you want to become a teacher in the Guitar Battle Club.</p>
                <button onClick={onLogInTextClick} className="bttn bttn-stroke mx-auto">Register now</button>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default HowToPlay;
