import React, { FC } from 'react';


interface DashboardElementProps {
  title: string;
  description: string;
  buttontext: string;
  onClickButton: () => void;
}

const DashboardElement: FC<DashboardElementProps> = (props) => (
  <div className="card-songs p-3 p-md-4 h-100 d-flex flex-column justify-content-between">
    <div className="mb-5 d-flex flex-column">
      <h3 className="fs-3 m-0 fw-normal">{props.title}</h3>
      <p className="mb-5">{props.description}</p>
    </div>
    <div onClick={props.onClickButton} className="bttn align-self-start">
      {props.buttontext}
    </div>
  </div>
);

export default DashboardElement;
