import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import LearnSongsCard, { LearnSongsCardType } from './LearnSongsCard';
import ReactPaginate from 'react-paginate';
import { on } from 'events';


export interface SongCardsContainerProps {
  songs: LearnSongsCardType[],
  containerPosition?: CSSProperties["position"];
  containerTop?: CSSProperties["top"];
  containerLeft?: CSSProperties["left"];
  childAction: (song: LearnSongsCardType) => void;
  showLevel?: boolean;
  onPageChange?: (data: { selected: number }) => void;
  pageCount: number;
}

const SongCardsContainer: FC<SongCardsContainerProps> = ({ songs, childAction, containerPosition, containerTop, containerLeft, showLevel, pageCount, onPageChange }) => {

  const containerStyle: CSSProperties = useMemo(() => {
    return {
      position: containerPosition,
      top: containerTop,
      left: containerLeft,
    };
  }, [containerPosition, containerTop, containerLeft]);

  const handlePageClick = (data: { selected: number }) => {
    onPageChange && onPageChange(data);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {songs && (
        <div className="row" style={containerStyle}>
          {songs.map((song, index) => (
            <LearnSongsCard
              key={song.id}
              id={song.id}
              artistName={song.artistName}
              songTitle={song.songTitle}
              subLevel={song.subLevel}
              level={showLevel ? song.level : undefined}
              thumbnailUrl={song.thumbnailUrl}
              isUserLesson={song.isUserLesson}
              hasUserVideo={song.hasUserVideo}
              hasUserBattle={song.hasUserBattle}
              action={() => childAction(song)}
            />
          ))}
          <div className="col-12">
            <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="previous"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SongCardsContainer;
