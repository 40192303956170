import api, { apiBaseUrl } from "./config/apiConfig";
import { Song } from "../models/Song";
import { Video } from "../models/Video";
import { logger as Logger, LogLevel } from "../services/Logger";
import axios from "axios";

export const getSongsByLevel = async (
  accessToken: string,
  level?: string,
  sublevel?: string,
  offset?: number,
  limit?: number
): Promise<Song[]> => {
  try {
    let url = `${apiBaseUrl}/songs`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        level: level,
        subLevel: sublevel,
        offset: offset,
        limit: limit,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get songs failed");
    }
  } catch (error: any) {
    if (error.response.status === 401) {
      let response = await getSongsPublic(level, sublevel, offset, limit);
      return response;
    } else {
      throw error;
    }
  }
};

export const getSongsPublic = async (
  level?: string,
  sublevel?: string,
  offset?: number,
  limit?: number
): Promise<Song[]> => {
  try {
    let url = `${apiBaseUrl}/songs/public`;

    const response = await api.get(url, {
      params: {
        level: level,
        subLevel: sublevel,
        offset: offset,
        limit: limit,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get songs failed");
    }
  } catch (error) {
    console.error("Get songs failed:", error);
    throw error;
  }
};

export const getSongById = async (
  accessToken: string,
  id: string
): Promise<Song> => {
  try {
    const response = await api.get(`/songs/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get song by id failed");
    }
  } catch (error) {
    console.error("Get songby id failed:", error);
    throw error;
  }
};

export const getSongPdf = async (
  accessToken: string,
  pdfUrl: string
): Promise<string> => {
  try {
    const response = await api.post(
      `/songs/pdf`,
      { pdfUrl: pdfUrl },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get song pdf failed");
    }
  } catch (error) {
    console.error("Get song pdf failed:", error);
    throw error;
  }
};

export const getUserVideoForSong = async (
  accessToken: string,
  songId: string
): Promise<Video> => {
  try {
    const response = await api.get(`/songs/${songId}/video`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get user video for song failed");
    }
  } catch (error) {
    Logger.error("Get user video for song failed:", error);
    throw error;
  }
};
