import axios, { AxiosInstance, AxiosError } from "axios";
import { useAuth, AuthContextProps } from "../../providers/AuthProvider";
import axiosRetry from "axios-retry";
import { useState } from "react";

export const apiBaseUrl = process.env.REACT_APP_API_URL;

const api: AxiosInstance = axios.create({
  baseURL: apiBaseUrl,
  timeout: 30000, // Timeout in milliseconds
});

axiosRetry(api, { retries: 3 }); // Retry the request up to 3 times

export const useApiInterceptor = (authProps: AuthContextProps) => {
  const { authState, logout } = authProps;
  const [networkError, setNetworkError] = useState(false);

  api.interceptors.request.use((config) => {
    const accessToken = authState?.tokens?.access_token;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      // console.log("Response received:", response);
      return response;
    },
    async (error: AxiosError) => {
      if (error.response?.status === 401) {
        await logout();
      }
      return Promise.reject(error);
    }
  );
};

export default api;
