import { FunctionComponent, useCallback, useEffect, useState } from "react";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import SongCardsContainer from "./SongCardsContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { LearnSongsCardType } from "./LearnSongsCard";
import { useAuth } from "../../providers/AuthProvider";
import SongToolsCard from "../songs/SongToolsCard";
import { Disable } from 'react-disable';

const LearnSongsSingleLesson: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { song } = location.state;
  const { authState } = useAuth();
  const { tokens } = authState;
  const similarSongs: any = [];
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!tokens?.access_token);

  useEffect(() => {
    setIsLoggedIn(!!tokens?.access_token);
  }, [tokens]);

  const onClickSong = useCallback(
    (song: LearnSongsCardType) => {
      navigate("/learn-song-play", { state: { id: song.id } });
    },
    [navigate]
  );

  const onLearnSongsTextClick = () => {
    navigate("/learn-song-list");
  };

  const onUploadVideoClick = () => {
    navigate("/upload-video", { state: { song: song } });
  };

  return (
    <>
      <div className="hero-section text-white d-flex align-items-center hero-single">
        <div className="container-gbc w-100">
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-5 order-2">
              <div className="d-flex flex-column justify-content-between">
                <SongToolsCard song={song} />
                <Disable disabled={!isLoggedIn}>
                  <div className="text-center p-4 rounded-4 bg-black text-white">
                    <h4 className="mb-3 mb-md-4">
                      Record and upload your video for feedback.
                    </h4>
                    <p className="mb-0">
                      After learning the song, you can record yourself and upload
                      your video, this way you will be able to receive and request
                      challenges and be part of Battles.
                    </p>
                    <div className="d-flex justify-content-center mt-3 mt-md-4">
                      <button
                        onClick={onUploadVideoClick}
                        className="bttn bttn-yellow align-self-start"
                      >
                        {song?.hasUserVideo ? "Try again" : "Upload your video"}
                      </button>
                    </div>
                  </div>
                </Disable>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-7 mb-3 mb-lg-0 order-1">
              <div className="bg-yellow d-flex flex-column video-container-outer border">
                <AWSVideoPlayerForm
                  videoUrl={song.videoUrl}
                  thumbnailUrl={song.thumbnailUrl}
                  isPrivate={true}
                />
                <div className="text-black p-4 p-md-5 border-top">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h3 className="fs-4 m-0 fw-bold">{song?.artistName}</h3>
                      <p className="mb-0 fs-6">{song?.songTitle}</p>
                    </div>
                    <div className="text-capitalize bg-white rounded-pill px-3 py-2 d-inline-flex bg-opacity-50">
                      <small>{song?.level} / {song?.subLevel} </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-yellow text-black border-top">
        <div className="container-gbc py-5">
          <div className="row text-center py-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  More songs available
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-5 col-md-8">
                  Please select a song you would like to learn.
                </p>
                <button onClick={onLearnSongsTextClick} className="bttn">
                  Back to song list
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-card bg-yellow">
        <div className="container-gbc">
          <SongCardsContainer
            childAction={() => onClickSong(song)}
            songs={similarSongs}
            pageCount={0}
          />
        </div>
      </div>
    </>
  );
};

export default LearnSongsSingleLesson;
